<template>
  <div>
    <!--    <main-tabs :tabs="tabs"></main-tabs>-->
    <RouterTabs :name="$route.name" :tab-arr="tabs"></RouterTabs>

    <router-view></router-view>
  </div>
</template>

<script>
// import MainTabs from '../../../base/components/Default/MainTabs'
import RouterTabs from '../../../base/components/Default/RouterTabs'

export default {
  components: {
    RouterTabs,
    //MainTabs
  },
  data() {
    return {
      tabs: [
        { label: '基本信息', name: 'VenueDetailInfo' },
        { label: '数据统计', name: 'VenueDetailStatistics' },
        { label: '使用记录', name: 'VenueDetailRecord' },
      ],
    }
  },
}
</script>
